
.order-cart-holder {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  bottom: -150px;
  transition: .4s all ease;

  &.och-sticky {
    bottom: 0;
  }

  &.unstick {
    bottom: -150px !important;
  }

}

.order-cart {
  @apply bg-[#D5E4FF] py-[10px];
}
