.footer {
  @apply font-normal text-base bg-blue-600 text-white;
  @screen lg {
    a:hover {
      @apply opacity-80;
    }
  }
}

.f-title {
  @apply text-lg md:text-xl mb-7 font-bold uppercase;
}

.convert-acc {
  .f-title {
    @apply pointer-events-none;
  }

  @screen mobile {
    @apply border-t border-gray-150/30 w-full -mb-px;
    .f-title {
      @apply py-4 m-0 cursor-pointer relative pointer-events-auto;
      svg {
        @apply absolute right-4 inset-y-0 my-auto;
      }

      &.active svg {
        @apply transform rotate-90;
      }
    }

    .foot-nav {
      @apply mb-6 hidden;
    }
  }
}

.foot-nav {
  @apply space-y-[11px]  md:space-y-[10px] mobile:text-sm
}
