$wh: #fff;
$bl: #000000;
#faq_pp2 {
  background-image: linear-gradient(360deg, #F6FAFF 0%, #FFFFFF 100%);

  .h1 {
    color: $wh;
    font-size: 24px;
    @include sm-max {
      font-size: 17px;
    }
  }

  #show_faq_pp2 {
    cursor: pointer;
    transition: text-decoration 0.3s ease;
    text-decoration: underline;
    transition-property: color;
    transition-duration: 0.3s;
    transition-timing-function: ease;

    &:hover {
      color: #eaeaea;
    }
  }

  .faqs-wrapper {
    max-width: 780px;


    .faq-pp2 {
      border-radius: 5px;
      background-color: $wh;
      transition-property: background-color, color;
      transition-duration: 0.3s;
      transition-timing-function: ease;
      
      background-color: $wh;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

      & > * {
        color: $bl;
        transition-property: background-color, color;
        transition-duration: 0.3s;
        transition-timing-function: ease;

      }
      &:hover {
        @apply bg-[#3872F3];

        & > * {
          color: $wh;
        }

        & svg {
          fill: $wh
        }
      }

      &.active {
        p {
          font-size: 16px;
          @media screen and (max-width:1023px) {
            font-size: 14px;
          }
          a {
            font-weight: 700;
          }
        }

        .font-medium {
          color: $bl;
          font-weight: 700;
          margin: 15px 0;
        }

        background-color: #ffffff;

        & > * {
          color: $bl;
        }

        & svg {
          fill: $bl
        }

        &:hover {
          background-color: #ffffff;

          & > * {
            color: $bl;
          }

          & svg {
            fill: $bl
          }
        }

        svg {
          transform: rotate(90deg)
        }
      }

      .question {
        font-weight: 700;
        line-height: 24px;
        cursor: pointer;
        font-size: 20px;
        @media screen and (max-width:1023px) {
          font-size: 18px;
        }
        @include md-max {
          font-size: 16px;
        }

        svg {
          transition-property: fill, transform;
          transition-duration: 0.3s;
          transition-timing-function: ease;
          margin-left: 24px;
        }
      }

      .answer {
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        font-size: 18px;
        @include md-max {
          font-size: 14px;
          line-height: 26px;
        }

        &:first-child{
          font-weight: 700;
        }
        &:not(:first-child){
          font-weight: 400;
        }
      }
    }
  }
}