.form-control {
  @apply transition-all outline-none text-gray-350 duration-150 border border-gray-200 w-full rounded px-3 pt-3 pb-0;
  height: 50px;
  @include md-max {
    @apply text-[16px];
  }

  .fill & ~ label,
  &:focus ~ label {
    font-size: 10px;
    transform: translateY(-9px);
  }
}

select.form-control {
  appearance: none;
  @apply pr-8;
  background: #fff url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='9'><path d='M7.027 8.998a.982.982 0 0 1-.696-.288L.288 2.675a.982.982 0 0 1 0-1.39.985.985 0 0 1 1.392 0l5.347 5.34 5.347-5.34a.985.985 0 0 1 1.392 0 .981.981 0 0 1 0 1.39L7.723 8.71a.982.982 0 0 1-.696.288Z' fill='%23828282'/></svg>") no-repeat calc(100% - 10px) 60%;

  .fill & ~ label,
  & ~ label {
    font-size: 10px;
    transform: translateY(-9px);
  }
}

.form-group {
  @apply relative mb-5 md:mb-2;
  label {
    @apply transition-all duration-150;
    @apply absolute inset-0 font-normal pointer-events-none flex items-center px-3 text-gray-300;
  }
}

.checkbox {
  @apply relative p-0 mt-0 block ml-0 mb-5 text-xs;
  a {
    @apply text-primary;
    @screen lg {
      &:hover {
        @apply underline;
      }
    }
  }

  .check-label {
    @apply block relative;
    padding-left: 26px;

    &:before {
      content: "";
      @apply w-4 h-4 border bg-white border-gray-200 absolute left-0 top-0;
    }
  }

  input:checked ~ .check-label:before {
    @apply bg-primary border-primary;
    background-repeat: no-repeat;
    background-position: 50% 55%;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='11'><path d='M11.523.197c-.518-.336-1.245-.232-1.623.228L4.027 7.584 2.073 5.383C1.674 4.936.944 4.86.442 5.213c-.504.352-.59 1.003-.192 1.448L4.1 11l7.676-9.36c.379-.461.265-1.107-.253-1.443Z' fill='%23fff'/></svg>");
    background-size: 60% auto;
  }

  input {
    @apply opacity-0 pointer-events-none absolute;
  }
}
