.header {
  @apply bg-white z-50 fixed top-0 inset-x-0 drop-shadow-sm;
}

.main-menu {

  a {
    @apply font-medium;
  }
  @screen lg {
    a:not(.btn) {
      &:hover {
        @apply text-gray-350;
      }
    }
  }
}

.menu-trigger {
  @apply lg:hidden  text-black;
  body:not(.active-menu) & .close {
    @apply hidden;
  }

  @screen mobile {
    @apply py-2;
    .active-menu & {
      @apply mt-1
    }
  }
  @screen tablet {
    .active-menu & {
      @apply mt-1
    }
  }

}

.cart-trigger {
  @apply text-secondary ml-auto mr-12 md:mr-14 lg:mr-0 -mt-1 lg:mt-0 lg:ml-10 cursor-pointer;
}

@screen tablet {
  .active-menu {
    .menu-trigger .open {
      @apply hidden;
    }

    .main-menu {
      @apply block px-[15px] md:px-5 overflow-auto bg-white text-center inset-x-0 fixed top-[100px] z-50 mobile:pb-2;
      max-height: calc(100vh - 48px);
      box-shadow: 0px 10px 10px -8px rgba(0, 0, 0, 0.15);
      @media screen and (min-width: 592px) {
        top:84px; 
      }

      @screen md {
        max-height: calc(100vh - 80px);
        top: 116px;
      }
      @screen mobile {
        @apply border-t border-gray-200;
      }
      a:not(.btn) {
        @apply py-[9px] md:py-3 block uppercase text-base;
      }

      .btn {
        @apply my-3 md:my-4 mobile:w-full mobile:h-[62px];
      }

      li + li {
        @apply border-t border-gray-200;
      }
    }

  }

  .active-cart {
    .body {
      @apply hidden;
    }

    .tablet-summary-box {
      @apply flex flex-col overflow-auto fixed inset-0 z-50 bg-white;
    }

    .header {
      @apply opacity-0;
      z-index: 55;

      .logo,
      .menu-trigger {
        @apply hidden;
      }
    }

    .cart-trigger {
      @apply fixed inset-x-0 mx-0 top-0 opacity-0 h-20;
      z-index: 55;
    }
  }

  .order-summary-container {
    position: relative;
    @media screen and (max-width: 1022px) {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999999;
    }
  }

  .order-summary-top {
    @apply px-[15px] md:px-5 bg-gray-50 text-gray-650 overflow-hidden;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);

    .h2 {

      .arr{
        transform: rotate(90deg);
      }

      &.active {

        .arr {
          transform: rotate(180deg);
        }
      }
    }
  }

  .order-cart {
    @apply order-2 mt-auto py-2;
  }
}
