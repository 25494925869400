@tailwind base;
@tailwind components;

@import "breakpoint-mixin";
@import 'vendors/swiper';
@import 'base/base';
@import 'base/buttons';
@import 'base/forms';
@import 'base/results-swiper';
@import 'components/header';
@import 'components/hero';
@import 'components/asseen';
@import 'components/features';
@import 'components/feature';
@import 'components/compare';
@import 'components/gallery';
@import 'components/reviews';
@import 'components/cart';
@import 'components/product';
@import 'components/faq';
@import 'components/faq-footer';
@import 'components/benefits';
@import 'components/middle_cta';
@import 'components/footer';

@tailwind utilities;
