html {
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

body {
  @apply flex flex-col font-normal justify-between min-h-screen;
  @apply bg-white text-gray-600 relative text-sm lg:text-base;
}

.container {
  @apply w-full mx-auto;

  max-width: 1180px;
  padding-left: 15px;
  padding-right: 15px;
  @screen md {
    @apply px-5;
  }
}

a {
  @apply transition duration-150;
}

.h1,
h1 {
  @apply font-display mb-5 text-primary font-semibold;
}

h1 {
  @apply mobile:text-[24px] tablet:leading-10 tablet:text-36 lg:text-36
}

.h1 {
  @apply text-[32px] tablet:leading-10 lg:text-36;
}

.h2,
h2 {
  @apply font-display mb-5  text-primary font-semibold;
  font-size: 24px;
  @screen md {
    font-size: 28px;
  }
  @screen lg {
    font-size: 32px;
  }
}

.h3,
h3 {
  @apply font-display mb-1 text-2xl text-primary font-semibold;
  @screen lg {
    font-size: 22px;
  }
}

.title-b {
  &:after {
    content: "";
    @apply bg-gray-200 mt-4 lg:mt-3 block rounded-xl;
    height: 7px;
    width: 100px;
    @screen md {
      height: 3px;
      width: 54px;
    }
    @screen lg {
      height: 7px;
      width: 100px;
    }
  }

  &.center {
    @apply text-center;
    &:after {
      @apply mx-auto;
    }
  }

  &.no-desc {
    &:after {
     @apply mt-7;
    }
  }
}

.bg {
  @apply object-cover bg-cover w-full h-full inset-0 absolute;
}

.background {
@media screen and (max-width:1023px) {
  @apply object-cover bg-cover w-full h-full inset-0 absolute;
  }
}

.backgroundV2 {
  @media screen and (max-width:767px) {
    @apply object-cover bg-cover w-full h-full inset-0 absolute;
    }
  }

@screen tablet {
  .stars-smaller {
    svg {
      width: 14px;
      height: 14px;
    }
  }
}

.iti__country-list {
  z-index: 20 !important;
}
