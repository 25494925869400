.slider-wrap {
  @apply relative z-10 flex mobile:text-center pt-8 pb-5;
  @screen md {
    @apply py-12;
    /*min-height: 830px;*/
  }
  @screen lg {
    @apply items-center text-left pt-0 min-h-[624px];
  }
}

.check-list {
  @apply text-gray-500 mobile:mt-5 md:mb-4 lg:mb-5 text-left;
  li {
    @apply pl-7 leading-4 md:leading-6 mb-3 md:mb-1 pb-px;
    background: url("/home_pp2_assets/images/check_g.svg") no-repeat 0 3px;
    background-size: 1rem 1rem;
    @screen mobile {
      background-position: 0 0;
    }
  }
}

#lightbox {
  @apply fixed top-0 bottom-0 left-0 right-0 z-[999999] hidden bg-black/[.85];

  #video-wrapper {
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
    @apply absolute top-1/2 left-1/2 z-[2] h-[170px] w-[300px];
    @screen sm {
      @apply h-[200px] w-[360px]
    }
    @include breakpoint-min-w(525px) {
      @apply h-[260px] w-[460px];
    }
    @screen sm {
      @apply h-[280px] w-[500px];
    }
    @screen md {
      @apply h-[360px] w-[640px];
    }
    @screen lg {
      @apply h-[480px] w-[854px];
    }
    @screen 2xl {
      @apply h-[720px] w-[1280px];
    }

    .hero-video {
      @apply h-full w-full;
    }
  }

  #close_btn {
    @apply text-gray-350 text-[25px] fixed top-[3%] right-[3%] z-[2];
    @apply md:hover:text-white md:hover:cursor-pointer;
    transform: scale(1, 1);
    transition: transform .5s ease, color .5s ease;
    &:hover {
      transform: scale(1.2, 1.2);
      transition: transform .5s ease, color .5s ease;
    }
    @screen mobile {
      @apply text-white;
      transform: scale(1.2, 1.2);
      transition: transform .5s ease, color .5s ease;
    }
  }
}

.hero {
  padding-bottom: 64px;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;

  @media screen and (max-width:1023px) {
    padding-top: 35px;
    padding-bottom: 35px;
  }

  @media screen and (max-width:767px) {
    padding-bottom: 32px;
    padding-top: 15px;
  }
}

.customPadding {
  padding-top: 100px;

  @media screen and (min-width:591px) {
    padding-top: 84px;
  }

  @media screen and (min-width:767px) {
    padding-top: 116px;
  }

}
