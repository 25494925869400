.feature-list {
  @apply flex items-center mb-5;
  @screen lg {
    margin-bottom: 30px;

  }
  .label {
    @apply text-base mobile:leading-5 lg:text-lg text-gray-500 order-1;
  }

  .round-icon {
    @apply mr-5 bg-gray-150 text-primary rounded-full flex items-center justify-center;
    width: 60px;
    height: 60px;
    min-width: 60px;
    @screen md {
      width: 53px;
      height: 53px;
      min-width: 53px;
    }
    @screen lg {
      width: 65px;
      height: 65px;
      min-width: 65px;

    }
  }

  &--left {
    @screen md {
      @apply justify-end;

      .label {
        @apply text-right -order-1;
      }

      .round-icon {
        @apply ml-5 mr-0;
      }
    }
  }
}

.features-container {
  @screen lg {
    @apply relative;
    &::before,
    &::after {
      @apply absolute top-5;
      content: '';
      background: center no-repeat url("/home_pp2_assets/images/f-bg.svg");
      width: 120px;
      height: 120px;
    }
    &::before {
      @apply -left-10;
    }
    &::after {
      @apply -right-10;
    }
  }
}
