.faq-item {
  @apply border-b border-gray-200;
  &:hover {

    .faq-head {
      @apply text-secondary;
    }
  }

  &.active {

    .faq-head {

      svg {
        @apply transform rotate-90;
      }
    }
  }
}

.faq-head {
  @apply cursor-pointer relative py-3 pr-8 lg:py-4 leading-6 lg:leading-8 capitalize text-primary text-base lg:text-xl font-display font-bold;
  svg {
    @apply absolute right-0 inset-y-0 my-auto;
  }

  /*&.active svg {
    @apply transform rotate-90;
  }*/
}

.faq-cc {
  @apply hidden text-gray-500 tablet:leading-6 text-sm pb-2 lg:pb-5;
  &.active {
    @apply block;
  }
}

.answer {

  p:not(.font-medium) {
    @apply leading-normal;
  }
}
