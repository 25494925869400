.swiper-compare {
  .labels {
    @apply bg-secondary flex lg:py-[5px] text-center text-xs md:text-[10px] lg:text-xs text-white mobile:mb-2;
  }

  .stars {
    @apply flex  mb-2 lg:mb-3 justify-center space-x-[2px] text-yellow;
    @screen tablet {
      svg {
        width: 10px;
        height: 10px;
      }
    }
    @screen mobile {
      @apply mt-2;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.compare-img {
  @apply relative z-0 h-0;
  padding-bottom: 106.6%;


  img {
    @apply pointer-events-none w-1/2 z-10;
    object-position: 0 0;

    &.after {
      @apply right-0 z-0 w-full left-auto;
      object-position: 100% 0;
    }
  }

  input {
    @apply absolute flex top-0 opacity-0 z-30 bg-transparent m-0 items-center justify-center w-full h-full;
    -webkit-appearance: none;
    appearance: none;
    outline: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 6px;
      height: 600px;
      background: transparent;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 6px;
      height: 600px;
      background: transparent;
      cursor: pointer;
    }
  }

  .trigger {
    @apply bg-secondary z-20 pointer-events-none cursor-pointer w-[2px] lg:w-1 absolute inset-y-0 left-1/2;
    margin-left: -1px;
    @screen lg {
      margin-left: -2px;
    }
    &:before {
      content: "";
      @apply bg-secondary rounded-full absolute inset-y-0 my-auto left-1/2;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='34' height='12'><path d='M33.362 5.177 27.901.22a.702.702 0 0 0-.475-.178.702.702 0 0 0-.474.178l-.403.365a.571.571 0 0 0 0 .862l4.586 4.164-4.59 4.168a.579.579 0 0 0-.197.431c0 .163.07.316.196.431l.402.365a.702.702 0 0 0 .475.178c.18 0 .349-.063.475-.178l5.466-4.963a.579.579 0 0 0 0-.865ZM.983 5.177 6.443.22A.702.702 0 0 1 6.92.041c.18 0 .348.063.474.178l.402.365a.571.571 0 0 1 0 .862L3.21 5.61 7.8 9.778a.579.579 0 0 1 .197.431c0 .163-.07.316-.196.431l-.403.365a.702.702 0 0 1-.474.178.702.702 0 0 1-.475-.178L.983 6.042a.579.579 0 0 1 0-.8' fill='%23fff'/></svg>");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: 75% auto;
      width: 28px;
      margin-left: -14px;
      height: 28px;
      @screen lg {
        width: 46px;
        margin-left: -24px;
        height: 46px;
      }
    }
  }
}
