@mixin lg-max {
    @media screen and (max-width: 1140px) {
      @content;
    }
  }
  
  @mixin lg-min {
    @media screen and (max-width: 1141px) {
      @content;
    }
  }


@mixin tb-max {
  @media screen and (max-width: 920px) {
    @content;
  }
}

@mixin tb-min {
  @media screen and (max-width: 921px) {
    @content;
  }
}
  
  @mixin md-max {
    @media screen and (max-width: 768px) {
      @content;
    }
  }
  
  @mixin md-min {
    @media screen and (min-width: 769px) {
      @content;
    }
  }

  @mixin sm-max {
    @media screen and (max-width: 660px) {
      @content;
    }
  }
  
  @mixin sm-min {
    @media screen and (max-width: 661px) {
      @content;
    }
  }

  @mixin s-max {
    @media screen and (max-width: 474px) {
      @content;
    }
  }
  
  @mixin xs-max {
    @media screen and (max-width: 374px) {
      @content;
    }
  }
  
  @mixin xs-min {
    @media screen and (max-width: 375px) {
      @content;
    }
  }

  @mixin breakpoint-w($width) {
    @media screen and (max-width: $width) {
      @content;
    }
  }

@mixin breakpoint-min-w($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}
  
  @mixin breakpoint-h($height) {
    @media screen and (max-height: $height) {
      @content;
    }
  }
  
  @mixin breakpoint-both($height, $width) {
    @media screen and (max-height: $height) and (max-width: $width) {
      @content;
    }
  }
