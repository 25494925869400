.btn {
  @apply bg-secondary transition inline-flex justify-center items-center duration-150 align-top py-4 leading-6 px-7 rounded text-white font-medium text-lg;
  height: 54px;
  @screen lg {
    &:hover {
      @apply bg-blue-200;
    }
  }
  &.swiper-button-next,
  &.swiper-button-prev {
    @apply p-0 rounded-full my-auto mx-6 mobile:mx-4 z-10 absolute inset-y-0;
    width: 29px;
    height: 29px;
  }

  &.btn-help {
    @apply font-medium text-sm uppercase px-3 md:px-5 py-1 h-10;
    letter-spacing: 0.07em;
    @apply bg-gray-150 text-gray-800;
    &:before {
      content: "";
      @apply mr-2 duration-150 transition-all;
      background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='17' height='15'><path d='M14.2 5.7c-.2 0-.5 0-.8.1-.6.1-2.2.2-2.8 0 .6-1.6.5-3.8.5-4.3v-.2l-.1-.2c-.4-.6-1-.9-1.6-.9-.6 0-1.1.4-1.4.9l-.2.3C7.2 4.6 5.5 5.5 5 5.7H.8v9.1h5.1v-.3c.4.2 1 .3 1.6.3h5.8c2.2 0 2.9-6.3 2.9-6.9 0-1.6-1.2-2.2-2-2.2zm-9.8 7.5H2.2v-6h2.1v6zm8.9 0H7.5c-.8 0-1.4-.4-1.6-.6V6.9c.9-.5 2.7-1.8 3.4-5.1 0-.1.1-.1.2-.1 0 0 .1 0 .2.1 0 1.2-.1 2.7-.5 3.6v.1c-.2.5-.1.9.1 1.2.7.8 2.2.7 4.2.6h.7c.2 0 .6.1.6.6 0 1.4-.9 4.9-1.5 5.3z' fill='%23333'/></svg>") no-repeat;
      width: 17px;
      height: 15px;
    }

    &:hover {
      &:before {
        filter: brightness(0) invert(1);
      }
    }

    &.active {
      &:before {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='17' height='15'><path d='M14.2 5.7c-.2 0-.5 0-.8.1-.6.1-2.2.2-2.8 0 .6-1.6.5-3.8.5-4.3v-.2l-.1-.2c-.4-.6-1-.9-1.6-.9-.6 0-1.1.4-1.4.9l-.2.3C7.2 4.6 5.5 5.5 5 5.7H.8v9.1h5.1v-.3c.4.2 1 .3 1.6.3h5.8c2.2 0 2.9-6.3 2.9-6.9 0-1.6-1.2-2.2-2-2.2z' fill='%2301b157'/></svg>");
      }
    }

    @screen lg {
      &:hover {
        @apply bg-green-100 text-white;
      }
    }
  }
}
