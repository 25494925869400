.foot-feature {
  @apply md:col-span-3 py-4 lg:px-4 relative mobile:text-base;
  img {
    @apply mx-auto mb-4 h-14 md:h-11 lg:h-14;
  }

  & + .foot-feature {
    &:before {
      content: "";
      @apply mobile:h-px md:w-px bg-secondary absolute md:-left-8 opacity-30 mobile:inset-x-0 top-0 md:inset-y-0;
    }
  }
}
