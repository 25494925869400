.product-card {
  @apply block bg-white mb-3 md:mb-6 lg:mb-14 px-6 relative pt-7 mt-2 lg:mt-12 pb-5 lg:pb-6 md:text-center border border-gray-200 rounded;

  .save {
    @apply bg-blue-700 text-[10px] md:text-xs lg:text-sm text-white font-bold px-1 py-[3px] md:px-2 md:py-[10px] lg:py-2 inline-block;

    @media screen and (max-width:767px) {
      position:relative;
      left:59%;
    }
  }

  .price-w {
    @apply block md:flex items-end justify-center mt-1 md:mt-5 lg:mt-2 md:pb-px;

    @media screen and (max-width:767px) {
      position:relative;
      left:59%;
    }

  }

  .current {
    @apply block text-xl mt-1 md:mt-[0px] md:text-[24px] lg:text-[30px] lg:text-xl font-bold text-gray-500 mobile:flex items-end mobile:-mb-px;

  }

  .total {
    @apply block border-t mobile:text-left pt-1 md:py-2 tablet:mt-1 lg:pt-2 lg:pb-3 border-gray-200 text-[10px] md:text-xs lg:text-sm text-gray-500 font-semibold md:font-bold;
  
    @media screen and (max-width:767px) {
      position:relative;
      left:59%;
      width:41%;
    }

  }

  .del {
    @apply block text-[10px] md:text-xs lg:text-sm text-orange line-through mr-2 mobile:-mb-2;
  }

  .list {
    @apply block md:inline-block text-left tablet:leading-6 mobile:leading-5 text-gray-500 mobile:text-xs mobile:-mr-2;

    @media screen and (max-width:767px) {
      position:relative;
      left:59%;
    }

    .li {
      @apply block relative pl-4 md:pl-6 pb-px;

      svg {
        @apply fill-current absolute left-0 top-1 mobile:w-3 mobile:h-3;
      }
    }
  }

  .title {
    @apply block text-base lg:text-lg mb-3 font-bold text-gray-500;
    @screen mobile {
      @apply absolute inset-0 pt-6 text-center;
      &:before {
        content: "";
        @apply absolute w-5 h-5 inset-y-0 my-auto left-4 z-10 border border-primary;
      }
    }

    &.active {
      &:before {
        @apply bg-primary;
      }

      &:after {
        content: "";
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293z'/%3E%3C/svg%3E");
        @apply absolute w-5 h-5 inset-y-0 my-auto left-4 z-10;
      }
    }
  }

  .qnt {
    @apply block md:hidden absolute text-sm font-semibold left-1/2 -mt-2 top-[13rem] z-20 bg-[#21CDC0] border border-[#22B5AA] w-[35px] h-[35px] text-center flex items-center justify-center rounded-full -ml-6;
  }

  .img {
    @apply mx-auto mb-3 lg:mb-2 tablet:h-28;
    @screen mobile {
      @apply h-[160px] w-[86px] md:w-[unset] md:h-[106px] left-0 right-1/2 mr-2 pl-2 top-16 mt-1 absolute;
    }
  }

  input {
    @apply absolute opacity-0 pointer-events-none inset-0 my-auto h-6;
  }


  @screen mobile {
    @apply pt-16;

    input:checked ~ .title {
      &:before {
        @apply bg-primary;
        background-repeat: no-repeat;
        background-position: 50% 55%;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='11'><path d='M11.523.197c-.518-.336-1.245-.232-1.623.228L4.027 7.584 2.073 5.383C1.674 4.936.944 4.86.442 5.213c-.504.352-.59 1.003-.192 1.448L4.1 11l7.676-9.36c.379-.461.265-1.107-.253-1.443Z' fill='%23fff'/></svg>");
      }
    }
  }
}

.product-card:hover .btn {
  animation: bounce .5s linear;

}

.totalAndList{

}

@keyframes bounce {
  20%,50%,80%,to{
    transform: translate(0);
  }
  40%{
    transform: translateY(-3px);
  }
  70%{
    transform: translateY(-2px);
  }
  90%{
    transform: translateY(-1px);
  }
 }