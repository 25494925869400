.middle-cta {

  .bg-img-wrapper {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    @apply bg-gray-150 relative;
    @screen lg {
      &::before,
      &::after {
        content: '';
        background: center no-repeat url("/home_pp2_assets/images/m-cta-bg-el.svg");
        width: 90px;
        height: 125px;
        @apply absolute -z-10;
      }
      &::before {
        @apply -top-8 -left-8;
      }
      &::after {
        @apply -bottom-8 -right-8;
      }
    }
    @screen mobile {
      background-image: none;
    }
    @screen md {
      background-image: url('/home_pp2_assets/images/m-cta@2x.jpg');
      min-height: 550px;
    }
    @screen lg {
      min-height: 863px;
    }
  }
}
