.studies-results {

  .swiper-pagination {
    bottom: 0 !important;

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      @apply bg-transparent border border-blue-500 opacity-100;

      &.swiper-pagination-bullet-active {
        @apply bg-blue-500;
      }
    }
  }
}
