
.review {
    @apply text-gray-500 relative border-b border-gray-200 pt-2 md:pt-7 pb-5 mobile:flex flex-col;
    .stars {
        @apply flex space-x-[3px] text-yellow mr-3 mobile:mb-3;
    }
    .verified {
        @apply rounded-2xl flex items-center mobile:mb-[8px] leading-normal px-2 py-[2px] md:py-1 border border-gray-100 text-green-100;
        font-size: 10px;
    }
    .date {
        @apply absolute right-0 py-1 text-sm mobile:text-xs;
    }
    .images {
        @apply flex space-x-2 pt-5 mobile:order-4;
        img {
            @apply  w-[170px] mobile:w-full;
        }
    }
    .name {
        @apply flex items-center text-xs mobile:mb-[8px] font-bold mr-3;
    }
    .meta {
        @apply flex flex-wrap mb-3 md:mb-5 items-center;
    }
    .acts {
        @apply flex items-center md:space-x-2 text-sm mt-5  mobile:order-5;
        .btn {
            @apply mr-5 md:mr-3;
        }
    }
    .recommend {
        @apply flex items-center md:mb-5 mobile:mt-4 text-xs md:text-sm mobile:order-3;
        svg {
            @apply fill-current text-green-100 mr-3 mobile:w-3 mobile:mr-2;
        }
    }
}
